export const LANDING = '/';
export const ABOUT = '/about';
export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const SIGN_IN_ANON = '/signinanon';
export const ACCOUNT = '/account';
export const PASSWORD_FORGET = '/pw-forget';
export const ADMIN = '/siteadmin';
export const ADMIN_DETAILS = '/admin/:id';

export const START = '/start';
export const RESTART = '/restart';
export const VERIFIED = '/verified';
export const STEP1 = '/step_1';
export const STEP2 = '/step_2';
export const STEP3 = '/step_3';
export const STEP4 = '/step_4';
export const STEP5 = '/step_5';
export const SAVERESULTS = '/save_results';
export const RESULTS = '/results';
export const RESULTSTEAM = '/results-team-activities';
export const RESULTSSKILLS = '/results-skills-development';
