import * as React from "react"

function UseIcon(props) {
  return (
    <svg width={84} height={81} viewBox="0 0 84 81" fill="none" {...props}>
      <path
        d="M69.335 27.863c.2-.1.3-.2.3-.2l.1-.1s.1 0 .1-.1l12.8-12.7c.4-.4.6-.9.6-1.4 0-.5-.2-1-.6-1.4l-9.9-9.9c-.4-.4-.9-.6-1.4-.6-.5 0-1 .2-1.4.6l-12.8 12.7s0 .1-.1.1l-.1.1c-.1.1-.1.2-.2.3l-.1.1-3.4 7.4-9.5 9.7-6.9-7c2.5-6.9.8-14.7-4.4-20-5.5-5.5-14.1-7-21.3-3.8-.6.3-1 .8-1.1 1.5-.1.6.1 1.3.5 1.8l9.8 9.8-5.6 5.7-9.8-9.8c-.5-.5-1.1-.7-1.8-.6-.6.1-1.2.5-1.5 1.1-3.2 7.2-1.6 15.7 3.9 21.3 5.2 5.2 13.1 6.9 20 4.4l3.4 3.4-22 21.9c-4.3 4.3-4.3 11.3 0 15.6 2.1 2.1 5 3.2 7.8 3.2s5.6-1.1 7.8-3.2l21.9-21.9 21.2 21.1c1.6 1.6 3.6 2.3 5.7 2.3 2 0 4.1-.8 5.6-2.3 3.1-3.1 3.1-8.2 0-11.3l-24.7-24.7 9.7-9.6 7.4-3.5zm2-21.6l7.1 7.1-9.9 9.9-7.1-7.1 9.9-9.9zm-55.6-1.9c4.3-1 8.9 0 12.4 2.7l-4.9 4.9-7.5-7.6zm11.7 28.7c-.4-.4-.9-.6-1.4-.6-.3 0-.5.1-.8.2-5.7 2.6-12.4 1.3-16.8-3-3.7-3.7-5.1-9-4-13.9l8.9 9c.8.8 2.1.8 2.8 0l8.5-8.5 6.3-6.3c3.3 4.3 4 10.2 1.7 15.3-.3.8-.2 1.7.4 2.2l7.8 7.9-5.7 5.7-7.7-8zm-7.7 41.8c-2.7 2.7-7.2 2.7-9.9 0s-2.7-7.2 0-9.9l1.4-1.4 9.9 9.9-1.4 1.4zm4.2-4.2l-9.9-9.9 17.7-17.7 9.9 9.9-17.7 17.7zm50.2-2.2c1.6 1.5 1.6 4.1 0 5.7-1.6 1.6-4.1 1.6-5.7 0l-22.6-22.6-2.1-2.1 5.7-5.7 24.7 24.7zm-33.2-21.9l-2.8-2.8 17-17 2.8 2.8-17 17zm16.5-23.1l1.8-3.9 5.8 5.8-3.9 1.8-3.7-3.7z"
        fill="#341B6F"
      />
    </svg>
  )
}


function ReadIcon(props) {
  return (
    <svg width={94} height={69} viewBox="0 0 94 69" fill="none" {...props}>
      <path
        d="M21.813.002S13.918.608 9.375 2.096A2 2 0 008 4.002v7.094a61.012 61.012 0 00-6.937 3.156A2 2 0 000 16.033v50a2 2 0 002.938 1.75c6.913-3.672 14.016-5.806 21.03-5.75H24c8.52 0 16.32 2.215 21.063 4.72a2 2 0 00.937.25h2a2 2 0 00.938-.25c4.748-2.508 12.546-4.75 21.062-4.75h.031c7.015-.058 14.118 2.077 21.032 5.75A2 2 0 0094 66.002v-50a2.002 2.002 0 00-2.772-1.874A2.001 2.001 0 0090 16.002v46.844c-6.461-3.028-13.19-4.893-20-4.844h-.031a53.773 53.773 0 00-16.5 2.625C59.06 57.611 65.293 56.002 72 56.002c4.153 0 7.53.647 11.375 1.906A1.999 1.999 0 0086 56.002v-52a2 2 0 00-1.375-1.906C80.493.742 76.577.002 72 .002c-9.234 0-17.778 2.986-25 8.125C39.778 2.988 31.234.002 22 .002a2 2 0 00-.187 0zm.187 4c8.527 0 16.315 2.791 23 7.656v46.938c-6.797-4.28-14.625-6.594-23-6.594-3.602 0-6.79.581-10 1.438V5.596c3.725-.93 9.705-1.565 10-1.594zm50 0c3.64 0 6.713.593 10 1.563V53.44c-3.21-.857-6.398-1.438-10-1.438-8.407 0-16.187 2.44-23 6.75V11.721c6.69-4.874 14.463-7.719 23-7.719zM8 15.44v40.562a2 2 0 002.625 1.875c3.845-1.26 7.222-1.875 11.375-1.875 6.756 0 13.034 1.606 18.656 4.656-4.748-1.556-10.456-2.62-16.625-2.625h-.03C17.19 57.984 10.46 59.85 4 62.877V17.315a72.584 72.584 0 014-1.875z"
        fill="#341B6F"
      />
    </svg>
  )
}


function WatchIcon(props) {
  return (
    <svg width={89} height={65} viewBox="0 0 89 65" fill="none" {...props}>
      <path
        d="M84.8 0H3.2C1.4 0 0 1.5 0 3.3v49.2c0 1.8 1.4 3.2 3.2 3.2h81.7c1.8 0 3.2-1.4 3.2-3.2V3.3c0-1.8-1.5-3.3-3.3-3.3zM44.2 54.1c-.7 0-1.3-.6-1.3-1.3 0-.7.6-1.3 1.3-1.3.7 0 1.3.6 1.3 1.3 0 .7-.6 1.3-1.3 1.3zm39.9-4.7H3.9V4.6h80.2v44.8z"
        fill="#341B6F"
      />
      <path
        d="M36.272 16.3c.273.185.636.37 1 .555 5.45 3.234 10.993 6.375 16.444 9.61 1.727 1.016 1.727 1.755-.09 2.771a1484.178 1484.178 0 01-16.264 9.518C35.636 39.77 35 39.401 35 37.368V18.056c0-.832 0-1.664 1.272-1.756zM72.9 64.6c-.4-3.6-3-6.3-6-6.3h-6v-.1l-32.4.2H21c-3 0-5.4 2.6-5.9 6h57.8v.2z"
        fill="#341B6F"
      />
      <path d="M52 54.3H36.4v5.3H52v-5.3z" fill="#341B6F" />
    </svg>
  )
}

export { UseIcon, WatchIcon, ReadIcon }

